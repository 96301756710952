var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner__container d-flex flex-column justify-content-end",style:({
    backgroundImage: `url('${'https://admin.tumar.com/' + _vm.props.src}')`,
    'width': `${_vm.props.width}`,
    'height': `${_vm.props.height}`,

  })},[_c('div',{staticClass:"banner__content",style:({ alignSelf: _vm.props.alignSelf })},[(_vm.props.text)?_c('p',{style:({
        color: _vm.props.buttonTextColor,
      })},[_vm._v(" "+_vm._s(_vm.props.text)+" ")]):_vm._e(),_c('a',{attrs:{"href":_vm.props.buttonColor}},[(_vm.props.buttonHref)?_c('span',{style:({
          color: _vm.props.buttonTextColor,
        })},[_vm._v(" "+_vm._s(_vm.props.buttonHref)+" ")]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }