<template>
  <div>
    <div class="mailing-form">
      <input v-model="email" type="text" placeholder="Enter your email address" />
      <button @click="submitForm">SUBSCRIBE</button>
    </div>
    <p v-if="message"> {{ message }}</p>
    <p v-if="!validation"> Не правильно написали email</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      message:'',
      validation: true,
    }
  },
  watch: {
    // email(val) {
    //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    //   if(emailRegex.test(val)) {
    //     this.validation = true
    //   } else {
    //     this.validation = false
    //   }
    // }
  },
  methods: {
     async submitForm() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if(!emailRegex.test(this.email)) {
        this.validation = false
        return true
      }
      const res = await fetch('https://admin.tumar.com/api/frontend/subscribe', {
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({email: this.email}),
      }).then(res => res.json())

      if(res.status) {
        this.validation = true
        this.message = 'Email успешно добавлен'
      } else {
        this.validation = true
        this.message = 'Такой email уже есть'
      }
      // nursultan@gmail.com
    }
  }
}
</script>

<style lang="scss" scoped>
.mailing-form {
  width: fit-content;
  input {
    background-color: rgb(89, 89, 89);
  }
  input::placeholder {
    color: white;
  }
}

.mailing-form {
    width: 480px;
}

.mailing-form input {
    width: 370px;
    height: 40px;
    background-color: transparent;
    padding: 18px;
    color: black;
    border: 1px solid #BDBDBD;
}
.mailing-form input::placeholder {
    color: #BDBDBD!important;
    font-weight: bold;
}

button {
    background: #3E4151;
    height: 40px;
    padding: 5px 15px;
    width: 100px;
    color: white
}

@media (max-width: 450px) {
  .mailing-form input {
    width: 290px!important;
  }
}
</style>
