var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-wrapper row no-gutters"},[_c('section',{staticClass:"content-header"},[_c('div',{staticClass:"container-fluid mt-6"},[_c('div',{staticClass:"row mb-2 ml-0"},[_c('div',{staticClass:"col-sm-6"},[_c('h1',[_vm._v(_vm._s(_vm.$t('PRICE_LIST.price')))])]),_c('div',{staticClass:"col-sm-6"},[_c('ol',{staticClass:"breadcrumb float-sm-right"},[_c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.$t('PRICE_LIST.main')))])]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v(_vm._s(_vm.$t('PRICE_LIST.price')))])])])])])]),_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"col-3 py-0 empty-space"}),_c('div',{staticClass:"col-12 col-sm-9 py-0"},[_c('div',{staticClass:"right_b px-2 py-0"},_vm._l((_vm.priceList?.data),function({ reyestr, id }){return _c('ProductListItem',{key:id,attrs:{"item":reyestr},nativeOn:{"click":function($event){return _vm.$router.push({
              name: 'price list item',
              params: { id: id },
            })}}})}),1),_c('nav',{staticClass:"mb-16",attrs:{"aria-label":"Page navigation example"}},[_c('ul',{staticClass:"pagination justify-content-end"},[_c('li',{staticClass:"page-item disabled"},[_c('a',{staticClass:"page-link",attrs:{"href":"#","tabindex":"-1"},on:{"click":function($event){$event.preventDefault();return _vm.goToPage(_vm.currentPage - 1)}}},[_vm._v("Previous")])]),_vm._l((Array.from(
              {
                length: _vm.getTotalPages(_vm.priceList?.to, _vm.priceList?.per_page),
              },
              (_, index) => index + 1
            )),function(page,index){return _c('li',{key:index,staticClass:"page-item"},[_c('a',{staticClass:"page-link",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.goToPage(page)}}},[_vm._v(_vm._s(page))])])}),_c('li',{staticClass:"page-item"},[_c('a',{staticClass:"page-link",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.goToPage(2)}}},[_vm._v("Next")])])],2)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }