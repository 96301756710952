<template>
  <main class="container_order pa-5">
    <h1 class="bill__title ma-0 mt-3">{{ $t('ORDER_DETAILS.order_details') }}</h1>
    <ul class="bill__nav pa-0">
      <li class="mr-2"
        ><a href="#">{{ $t('ORDER_DETAILS.account') }}</a></li
      >
      <li class="mr-2"
        ><a href="#">{{ $t('ORDER_DETAILS.order_details') }}</a></li
      >
    </ul>
    <div class="order">
      <!-- <form>
        <div class="search-box">
          <v-text-field
            type="text"
            class="ma-0"
            placeholder="Seacrh + filter"
            hide-details
            append-icon="fas fa-search"
          ></v-text-field>
        </div>
      </form> -->
      <div class="order_table">
        <div class="row header_history no-gutters">
          <div
            :key="item"
            v-for="item in $t('ORDER_DETAILS.table')"
            class="col-sm-2 h_name"
            >{{ item }}</div
          >
        </div>
        <div
          v-for="item in orderDetails && orderDetails"
          :key="item.id"
          class="row body_history no-gutters"
        >
          <div class="col-sm-2 brand_img">
            <img :src="item?.brand && baseUrl + item?.brand?.photo" alt="" />
          </div>
          <div class="col-sm-2 text_grey text_u">
            {{ item?.brand && item?.brand?.name }}
          </div>
          <div class="col-sm-2 text_grey"
            >{{
              item && item?.current_employee_branding?.curren_amount
            }}
            pcs.</div
          >
          <div class="col-sm-4 text_l">
            <div class="history_actions">
              <div class="icon_c cursor-pointer" @click="openModal(item)">
                <span class="order_look">
                  <v-icon size="14">mdi-eye</v-icon>
                </span>
                <span>View</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <order-details-modal
      v-if="orderDetails"
      :selectedOrders="selectedOrders"
      @closeModal="isShowModal = false"
      :modal="isShowModal"
      :index="index"
      :history="history"
    >
    </order-details-modal>
  </main>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
import OrderDetailsModal from './store/OrderDetailsModal.vue'
const { mapActions, mapState } = createNamespacedHelpers('brandDoubt')
export default {
  name: 'OrderDetails',
  components: {
    OrderDetailsModal,
  },
  data() {
    return {
      order: [],
      baseUrl: '',
      isShowModal: false,
      selectedOrders: [],
      history: null,
    }
  },
  computed: {
    ...mapState(['orderDetails']),
  },
  methods: {
    ...mapActions(['fetchOrderDetails', 'fetchOrderDetailsById']),
    async openModal(order) {
      this.history = order
      const res = await this.fetchOrderDetailsById(order?.brand?.id)
      this.isShowModal = true
      this.selectedOrders = res.data.data?.reverse()
    },
  },
  created() {
    this.baseUrl = this.$store.state.auth.API_URL
    this.fetchOrderDetails()
  },
}
</script>
<style scoped lang="scss">
@import url('https://netdna.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css');
@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans:ital,wght@0,100;0,300;0,400;0,500;0,700;0,800;1,300;1,400;1,500;1,700;1,800&display=swap');

$white: #ffffff;
$grey: #b5b5b5;
$gr: #f5f5f5;
$bl: #423f3d;
$black: #31343c;
$gr_c: #fbfbfb;
$grtx: #a4a4a4;
$green: #cfebcc;
$gr_col: #818181;
$green_c: #a3c2a0;
$bl_c: #90ace2;

i {
  font-family: fontawesome !important;
}

@mixin ftz($fs, $fw, $col) {
  font-size: $fs;
  font-weight: $fw;
  color: $col;
}

%d_flex {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
}

.bill__title {
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: uppercase;
}

.order {
  background: $white;

  @media (max-width: 840px) {
    overflow: scroll;
  }

  .search-box {
    position: relative;
    margin: 14px 23px;
    overflow: hidden;
    .v-text-field {
      border: 1px solid $gr;
      border-radius: 1px;
      padding: 2px 10px 2px 25px;
      width: 21rem;
      @include ftz(0.875rem, 500, $grey);
    }
  }

  .order_table {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    @media (max-width: 996px) {
      overflow: scroll;
    }

    .header_history {
      @extend %d_flex;
      width: 100%;
      height: 40px;
      background: $gr_c;
      border-radius: 1px;
      margin-right: 1rem;

      @media (max-width: 541px) {
        .col-sm-2 {
          width: 20%;
        }
        .col-sm-4 {
          width: 40%;
        }
      }

      .h_name {
        @include ftz(0.875rem, 500, $bl);
        padding-left: 64px;
      }
    }

    .body_history {
      border-bottom: 1px solid $gr;
      padding: 14px 0;
      @include ftz(0.875rem, 500, $bl);
      & > div {
        padding-left: 64px;
      }

      @media (max-width: 541px) {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        width: 100%;

        .col-sm-2 {
          width: 20%;
        }
        .col-sm-4 {
          width: 40%;
        }
      }

      .text_grey {
        color: $grtx;
      }
      .text_l {
        text-transform: lowercase;
      }
      .text_u {
        text-transform: uppercase;
      }
      .brand_img {
        img {
          width: 40px;
          height: 20px;
        }
      }

      .history_actions {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 541px) {
          overflow: inherit;
        }

        .icon_c {
          position: relative;
          display: flex;
          flex-wrap: nowrap;
          flex-direction: row;
          align-items: center;
          width: 33%;
          margin-right: 1rem;

          &:nth-child(3) {
            margin-right: 0;
          }

          @media (max-width: 1180px) {
            width: 49%;
            @media (max-width: 768px) {
              width: 60%;
            }
          }

          .order_look,
          .order_look_r,
          .order_look_e {
            display: flex;
            justify-content: center;
            width: 20px;
            height: 20px;
            border-radius: 20px;
            background-color: $green;
            border: 2px solid $green;
            margin-right: 4px;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.search-box {
  .v-input__slot {
    &:before,
    &:after {
      content: none !important;
    }
  }
  .v-icon {
    color: #423f3d;
    font-size: 16px;
  }
}
.bill__nav {
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 26px;

  li:not(:last-child) a {
    &::after {
      content: '';
      background-image: url('/img/icons/arrow-right-icon.svg');
      background-repeat: no-repeat;
      display: inline-block;
      width: 5px;
      height: 8px;
      margin-left: 7px;
    }
  }

  a {
    font-family: 'Alegreya Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #818181;
  }
}
</style>
