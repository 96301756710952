<template>
  <div
    class="banner__container d-flex flex-column justify-content-end"
    :style="{
      backgroundImage: `url('${'https://admin.tumar.com/' + props.src}')`,
      'width': `${props.width}`,
      'height': `${props.height}`,

    }"
  >
    <div class="banner__content" :style="{ alignSelf: props.alignSelf }">
      <p v-if="props.text"
        :style="{
          color: props.buttonTextColor,
        }"
      >
        {{ props.text }}
      </p>

      <a :href="props.buttonColor">
        <span
          v-if="props.buttonHref"
          :style="{
            color: props.buttonTextColor,
          }"
        >
          {{props.buttonHref}}
        </span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    props: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style lang="scss" scoped>
.banner__content p:first-child {
    font-size: 24px;
    color: white;
    font-weight: 700;
    margin-bottom: 11px!important;
}

.banner__content {
    text-align: center;
    margin-bottom: 64px;
    width: 400px;
}

.banner__content a p {
    font-size: 15px;
}
.banner {
  &__container {
    width: 100%;
    height: 200px;
    background-size: cover;
  }
  &__content {
    width: fit-content;
  }
}
</style>
