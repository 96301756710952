<template>
  <v-dialog class="v-dialog" :value="modal" persistent max-width="600">
    <v-card class="modal-card">
      <v-card-title>
        <div class="b-title">
          Important notice!
          <i @click="closeDialog" class="fas fa-close"></i>
        </div>
      </v-card-title>
      <v-card-text>
        <p v-if="modalType.type === 'enough'" class="b-details">
          <!-- У вас в запасе <b class="green">достаточно</b> логотипов
          {{ modalType.remainder }} для текущего заказа, мы спишем с вашего
          аккаунта, плату возьмем только за услугу пришития. -->
          You have <b class="green">enough</b> {{ modalType.remainder }} logos in stock for your current order, we will debit your account, we will only charge you for the sewing service.
        </p>
        <p v-else class="b-details">
          <!-- У вас в остатке всего {{ modalType.remainder }} шт логотипов, этого
          <b class="red">не достаточно</b> для текущиего заказа. В связи с этим,
          произойдет повторная докупка брендирования. -->
          You have only {{ modalType.remainder }} pcs of logos left, which is <b class="red">not enough</b> for the current order. Therefore, you will have to buy branding again.
        </p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'BrandingCountModal',
  props: {
    modal: {
      default: true,
      type: Boolean,
    },
    remainder: {
      type: Number,
    },
    modalType: {
      type: Object,
    },
  },
  methods: {
    closeDialog() {
      this.$emit('closeModal')
    },
  },
}
</script>
<style scoped lang="scss">
.red {
  color: red;
  background: none !important;
}

.green {
  color: green;
  background: none !important;
}
.v-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
}
// .v-dialog__content {
//   width: 400px !important;
//   height: 300px;
// }

.modal-card {
  height: 200px; /* Устанавливаем высоту модального окна на весь экран */
}
.b-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 25px;
}

.b-details {
  font-size: 18px;
}
</style>
