<template>
  <div v-if="isDesktop ? props.desktop : props.mobile" :id="props.id" :class="['container-v-craft flex-wrap']"   :style="{ ...props, width: '100%' }" >
    <template v-if="children.length">
      <recursion
        v-for="(ch, index) in children"
        :key="index"
        :content="ch"
        :componentName="
          ch.props.component ? ch.props.component : ch.componentName
        "
      />
    </template>
  </div>
</template>
  
<script>

export default {
  name: 'BuilderContainer',
  props: {
    props: {
      type: Object,
      default: () => {},
    },
    children: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isDesktop: window.innerWidth > 780,
    };
  },
  computed: {
    isView() {
      if(window.innerWidth > 980) {
        return true
      }else {
        return false
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.isDesktop = window.innerWidth > 780;
    },
  },
}
</script>

<style scoped>
.container-v-craft {
  background-position-x: center;
  
}
.container_mobile-v-craft.container_desktop-v-craft{
  display: flex !important;
  justify-content: center;
}

/* 
@media (min-width: 768px) {
  .container_mobile-v-craft {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .container_desktop-v-craft {
    display: none !important;
  }
}

@media (max-width: 560px) {
  .container_desktop-v-craft {
    display: block;
  }
} */

@media (max-width: 980px) {
  .crafted-component.container-v-craft {
    width: 100% !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    height: auto !important;
  }

  .crafted-component,
  .crafted-component *:not(>a) {
    width: 100% !important;
    height: auto !important;
  }

  .crafted-component.col-sm-4.col-md-3.col-lg-12.col-12 {
    padding: 20px !important;
  }
}


@media (max-width: 560px) {
  .crafted-component.container-v-craft.container_mobile-v-craft {
    /* padding: 20px !important; */
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .crafted-component,
  .crafted-component *:not(>a) {

  }
}
</style>