<template>
  <ul v-if="array.length" class="nav__link-dropdown-menu menu">
    <li v-for="item in array" :key="item.id" class="nav__link-dropdown pa-0">
      <v-hover v-slot="{ hover }">
        <div style="padding: 10px;">
          <span class="nav__link-dropdown-link" @click="$emit('select', item)">
            {{ item.title.ru }}
          </span>
          <category-recursion
            v-if="hover"
            :style="{ top: '0', left: left ? '-100%' : '100%' }"
            class="pa-0"
            :array="item.childrens"
            :left="left"
            @select="(category) => $emit('select', category)"
          />
        </div>
      </v-hover>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'CategoryRecursion',
  props: {
    array: {
      type: Array,
      default: () => [],
    },
    left: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
ul {
  padding: 0;
}
.menu {
  width: 150px;
  padding: 0 !important;
  top: 48px;
  li {
    padding-left: 0px;
  }
}
</style>
